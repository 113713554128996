@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
 text-decoration: none;
 

}

body{
  font-family: 'Roboto Condensed', sans-serif;
  
}


/* background: rgb(254,175,175); */

.nav{
/* background:linear-gradient(#0dccea, #0d70ea); */
background-image:linear-gradient(#dbb014, #f19021 );

}

.button {
  /* background-image:linear-gradient(#dbb014, #f19021 ); */
background:linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  /* font-size: .9em; */
  margin: 5px;
  padding: 6px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: all 0.2s ease-in-out;
}

.button:hover{
  background-image:linear-gradient(#917b2cb6, #f19021eb );

}

.btn-smoll{
 font-size: 68px;
 text-align: center;
 color: #dbb014;
}

.bg-mage{
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.Letest_card{
  width: 250px;
  /* height: 300px; */
  /* border: 2px solid red; */
  margin: 10px 2px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
}

.trending-line{
  width: 5px;
  height: 5px;
  padding: 5px;
  background-color: #f7c202;
  margin-right: 10px;
}


#title-Tag-Line {
  font-size: 20px;
}
/* .card-item__bg{
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: block;
  position: relative;
  margin: 30px auto;
  transform: translate(0px, 50px);
  z-index: 5;
} */

/* form animation starts */
.form {
  background: #fff;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 5px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  left: 0;
  right: 0;
  position: relative;
  border-top: 5px solid  #f19021;

/*   z-index: 1; */
  animation: bounce 1.5s infinite;
}
::-webkit-input-placeholder {
  font-size: 16px;
}

.title{
  display: block;
  font-family: sans-serif;
  margin: 10px auto 5px;
  width: 300px;
}
.termsConditions{
  margin: 0 auto 5px 80px;
}

.pageTitle{
  font-size: 2em;
  font-weight: bold;
}
.secondaryTitle{
  color: grey;
}

.name {
  background-color: #ebebeb;
  color: rgb(11, 10, 10);
}
.name:hover {
  border-bottom: 5px solid  #f19021;
  height: 30px;
  width: 380px;
  transition: ease 0.5s;
}

.email {
  background-color: #ebebeb;
  height: 2em;
}

.email:hover {
  border-bottom: 5px solid  #f19021;
  height: 30px;
  width: 380px;
  transition: ease 0.5s;
}

.message {
  background-color: #ebebeb;
  overflow: hidden;
  height: 10rem;
}

.message:hover {
  border-bottom: 5px solid  #f19021;
  height: 12em;
  width: 380px;
  transition: ease 0.5s;
}

.formEntry {
  display: block;
  margin: 30px auto;
  min-width: 300px;
  padding: 10px;
  border-radius: 2px;
  border: none;
  transition: all 0.5s ease 0s;
}

.submit {
  width: 200px;
  color: white;
  background-color:  #f19021;
  font-size: 20px;
}

.submit:hover {
  box-shadow: 15px 15px 15px 5px rgba(78, 72, 77, 0.219);
  transform: translateY(-3px);
  width: 300px;
  border-top: 5px solid #0e3750;
  border-radius: 0%;
}

@keyframes bounce {
  0% {
    tranform: translate(0, 4px);
  }
  50% {
    transform: translate(0, 8px);
  }
} 

/* footer */

.footer{
  border-radius: 49% 51% 100% 0% / 99% 100% 0% 1% ;
  background-color: #f19021;
}

.footer i{
  font-size: 28px;
}

.socila-link a{
  margin: 0px 5px;
  
}


/* Collcetion pages  */


.list button{
 /* width: 150px; */
 
}



/* about */


li{
  text-align: center;
  color: #777777f5;
  
}

.fulla{
  font-size: 18px;
  color: rgba(32, 30, 30, 0.995);
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
}











@media  all and (max-width:620px) {
    .form{
      max-width: 363px;
    }

    .buttonH{
      font-size: 14px;
    }

    .formEntry:hover{
      width: 0px;
  
    }

}

@media  all and (max-width:420px) {
  .form{
    max-width: 18rem;
  }

  .formEntry{
    min-width: 83%;
  }

 
}